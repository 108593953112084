import _ from 'lodash'
import React from 'react'
import { Router } from '@reach/router'
import OAuth from '../screens/oauth'

const OauthScreen = (props) => (
  <Router>
    <OAuth {...props} path={`${_.get(props, 'pageContext.locale')}/oauth/:action`} />
  </Router>
)

export default OauthScreen
