/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React, { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import URI from 'urijs'
import {
  cancelRequest, useAuth,
} from 'react-omnitech-api'
import { useLink, useAlert, useCart } from '../../hook'
import { isBrowser } from '../../helpers'
import OauthView from './oauth-view'

const OauthController = (props) => {
  // prepared hooks
  const { location, action } = props
  const { auth, createSession, setAuth } = useAuth()
  const { cartId, mergeCart } = useCart()
  const { navigate } = useLink()
  const { t } = useTranslation()
  const seoTitle = t('screens.authentication.seo.title')
  const alert = useAlert()
  const urlParams = useMemo(() => {
    let search = {}
    if (isBrowser()) {
      search = URI(location.href).search(true)
    }
    return search
  }, [location])

  /**
   * handleMergeCart
   * after user registration success, if a guest cart
   */
  async function handleMergeCart() {
    const options = {
      params: {
        includes: ['cart_line_properties'],
      },
    }
    try {
      await mergeCart(options)
    } catch (error) {
      // continue the login process
      console.warn('[Project] handleMergeCart error: ', error)
    }
  }

  async function handleCreateSession() {
  // prepare api call payload
    const data = {
      session: {
        approach: 'omni_auth_otp_udid',
        token: _.get(urlParams, 'omni_auth_otp'),
      },
    }

    // calling api for create session and control the flow of page redirect
    try {
      const { session } = await createSession(data)

      // update auth token and user id
      await setAuth({
        ...auth,
        ..._.pick(session, ['authToken', 'userId']),
      })
      if (!_.isEmpty(cartId)) {
        await handleMergeCart()
      }
      // handle redirect to different page after sign in successfully
      navigate(
        _.get(urlParams, 'extraParams', '/account/'),
        { replace: true },
      )
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      // alert.show(generalError.message)

      throw error
    }
  }
  useEffect(() => {
    if (urlParams.success === 'true') {
      if (_.includes(['login', 'register'], action)) {
        handleCreateSession()
      } else if (action === 'connect') {
        navigate(
          '/account/',
          { replace: false },
        )
      } else {
        // in case user write another acction
        navigate(
          '/login/',
          { replace: false },
        )
      }
    } else {
      navigate(
        '/account/profile',
        {
          replace: true,
          state: { errorMessage: _.replace(urlParams.error_message, '+', ' ') },
        },
      )
    }
    return () => {
      cancelRequest.cancelAll(['createSession'])
    }
  }, [])

  return (
    <OauthView seoTitle={seoTitle} />
  )
}

export default OauthController
