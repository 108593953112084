import React from 'react'
import { Layout, Loading, SEO } from '../../ui'

function OauthView({
  // the page will be ready after loading content group data is end
  pageReady,
  seoTitle,
}) {
  return (
    <Layout>
      {/* passing page specific data for SEO */}
      <SEO title={seoTitle} />
      {
        !pageReady && <Loading />
      }
    </Layout>
  )
}

export default OauthView
